app.dm.define("Channel", {
    "hash":          "@string",
    "tag":           "string",
    "title":         "string",
    "subtitle":      "string",
    "qrcode":        "boolean",
    "ribbon":        "boolean",
    "info":          "boolean",
    "shuffle":       "boolean",
    "randomStart":   "boolean",
    "publicVisible": "boolean"
});